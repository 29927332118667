import React, { useState, useEffect, useRef } from "react";

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "../../css/chat.css";
import "../../css/chatMobile.css";
import "../../css/global.css"

import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    ConversationHeader
} from "@chatscope/chat-ui-kit-react";

import { MessageOption } from "../ColumnChat"

import {
    MessageDirection,
} from "@chatscope/use-chat";

import api from "../api";
import Resizer from "react-image-file-resizer";
import { FileUploader } from "../FileUploader";

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            700,
            700,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "file"
        );
    });

export const ChatApp = () => {
    const [messages, setMsgs] = useState([]);

    const [sessionData, setSessionData] = useState(null);

    const [canType, setCanType] = useState(true)

    // const [fileName, setFileName] = useState("");
    const handleFile = async (file, index) => {
        // setFileName(file.name);

        try {
            // const file = event.target.files[0];
            const image = await resizeFile(file);
            const formData = new FormData();
            formData.append('file', image, sessionData.sessionId);
            formData.append('sessionId', sessionData.sessionId)

            await api.post('/image', formData)

            await handleMsg("Image sent.", {
                uploadIndex: index,
                uploadFile: image,
            })
        } catch (err) {
            console.log(err);
        }


    };

    const setupHistoryChat = (history) => {
        let newMsg = [...messages];

        for (let index = 0; index < history.length; index++) {
            const msgData = history[index];

            newMsg = [ // with a new array
                ...newMsg, // that contains all the old items
                { key: msgData.key, value: msgData.value }
            ]
        }

        setMsgs(newMsg)
    }

    useEffect(() => {
        // console.log("hello")
        const existingSession = localStorage.getItem('sessionData')

        if (existingSession) {
            const session_parse = JSON.parse(existingSession)
            api.get(`/session/${session_parse.sessionId}`, { withCredentials: true }).then(response => {
                if (response.data.sessionId === undefined) return

                setSessionData(response.data);

                localStorage.setItem('sessionData', JSON.stringify(response.data));

                console.log(response.data)

                if (response.data.history !== undefined) {
                    setupHistoryChat(response.data.history)
                }
            })
        } else {
            api.get('/session', { withCredentials: true })
                .then(response => {
                    setSessionData(response.data);

                    localStorage.setItem('sessionData', JSON.stringify(response.data));

                    console.log(response.data)

                    if (response.data.history !== undefined) {
                        setupHistoryChat(response.data.history)
                    }
                })
                .catch(err => {
                    console.error(err);
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleMsg = async (msg_value, selected_options) => {
        if (msg_value === "") {
            return;
        }

        setCanType(false)

        const newMsg1 = [ // with a new array
            ...messages, // that contains all the old items
            { key: "user", value: msg_value }
        ]

        setMsgs(newMsg1)

        const newTyping = [
            ...newMsg1,
            { key: "ai", value: "{typing}" }
        ]
        setMsgs(newTyping)

        const res = await api.post('/chat', {
            prompt: msg_value,
            sessionId: sessionData.sessionId,
            selectedOption: selected_options
        })

        if (typeof res.data == 'object') {
            // alert("process data")
            // alert(JSON.stringify(res.data))

            if (res.data.newSessionData && res.data.completeValue) {
                setSessionData(res.data.newSessionData)

                let newMsg2 = [
                    ...newMsg1,
                    { key: "ai", value: res.data.completeValue },
                    { key: "ai", value: "SHOW_OPTIONS" }
                ]

                setMsgs(newMsg2)
            }

        } else {
            let newMsg2 = [
                ...newMsg1,
                { key: "ai", value: res.data }
            ]

            setMsgs(newMsg2)
        }

        setCanType(true)
    }

    const selectIssueOption = async (event) => {
        event.preventDefault();

        const option = event.nativeEvent.submitter.innerHTML
        // console.log(option)

        const savedIndex = event.target.dataset.index
        event.target.remove()

        // console.log(option)

        await handleMsg(option, {
            optionsIndex: savedIndex
        })
    }

    const handleSend = async (msg) => {
        await handleMsg(msg)
    }

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [messages]);

    const options = [
        "REQUEST_CAPTURE_PHOTO",
        "SHOW_OPTIONS",
        "IF_STAFF",
        "GATCHA_SHOW_PROBLEMS",
        "DECK_LOCATE",
        "TOKEN_UNIT",
        "CHECK_A_SOLUTIONS",
        "REFUND_METHOD",

    ]

    const isLastMsgOption = options.includes(messages[messages.length - 1]?.value)

    const resetChat = async () => {
        setCanType(false)

        const res = await api.post(`/reset-session`, {
            sessionId: sessionData.sessionId,
        })

        setSessionData(res.data.newSessionData)

        localStorage.setItem('sessionData', JSON.stringify(res.data.newSessionData));

        window.location.reload();
    }

    return (
        <div style={{ position: "relative", width: "100dvw", height: "100dvh" }}>
            <MainContainer responsive={true} style={{ border: "none" }}>
                <ChatContainer >
                    <ConversationHeader className="header">

                        <ConversationHeader.Content userName="Tokyo Story Support">
                            <img alt="logo" height={"35px"} src={process.env.PUBLIC_URL + "logo.png"}></img>
                            <button onClick={resetChat}>
                                <span>New Chat</span>
                            </button>
                        </ConversationHeader.Content>
                    </ConversationHeader>

                    <MessageList className="messageList">
                        {messages.map((msgData, index) => {
                            let amtToRefundTokens = 0;
                            if (msgData.value.match("REFUND_METHOD")) {
                                amtToRefundTokens = msgData.value.replace(/[^0-9]/g, "");
                            }

                            let zelleInfo = []
                            if (msgData.value.match("ZELLE_CONFIRM")) {
                                zelleInfo = msgData.value.split("_")
                                console.log(zelleInfo)
                            }

                            return (
                                <Message
                                    model={{
                                        message: msgData.value,
                                        sentTime: "just now",
                                        sender: msgData.key,
                                        direction: msgData.key === "user" ? MessageDirection.Outgoing : MessageDirection.Incoming
                                    }}
                                >
                                    {
                                        msgData.value === "{typing}" &&
                                        <Message.CustomContent>
                                            <div style={{ margin: "0.5rem", display: "flex" }}>
                                                <div className="typing__dot"></div>
                                                <div className="typing__dot"></div>
                                                <div className="typing__dot"></div>
                                            </div>
                                        </Message.CustomContent>
                                    }

                                    {
                                        msgData.value.match("REQUEST_CAPTURE_PHOTO") !== null &&
                                        <Message.CustomContent>
                                            <span>Can you send me the image of the entire machine you're having problems with?</span>

                                            {msgData.value === "REQUEST_CAPTURE_PHOTO" &&
                                                <FileUploader handleFile={(file) => {
                                                    handleFile(file, index)
                                                }} />
                                            }

                                        </Message.CustomContent>

                                    }

                                    {
                                        msgData.value.match("TOKENS_LOST") !== null &&
                                        <Message.CustomContent>
                                            <span>How many tokens did you lose?</span>
                                        </Message.CustomContent>
                                    }

                                    {
                                        msgData.value.match("SHOW_OPTIONS") !== null &&
                                        <Message.CustomContent>
                                            <span>Hello, what problems are you experiencing today?</span>

                                            {msgData.value === "SHOW_OPTIONS" &&
                                                <form style={{ marginTop: (msgData.value === "SHOW_OPTIONS" ? "1rem" : "0") }} data-index={index} onSubmit={selectIssueOption}>
                                                    <MessageOption type="submit">A) Capsule toy machine issue</MessageOption>
                                                    <MessageOption type="submit">B) Coin dispenser issue</MessageOption>
                                                    <MessageOption type="submit">C) Something else</MessageOption>
                                                </form>
                                            }

                                        </Message.CustomContent>
                                    }

                                    {
                                        msgData.value.match("REQUEST_CONTACT_INFO") !== null &&
                                        <Message.CustomContent>
                                            <span>Can you provide us with an email or phone number, so we can contact you for further questions or information?</span>
                                        </Message.CustomContent>
                                    }

                                    {
                                        msgData.value.match("IF_STAFF") !== null &&
                                        <Message.CustomContent>
                                            <span>Is there any staff nearby? They are typically around the token machine, but might be attending to other machines as well.</span>

                                            {msgData.value === "IF_STAFF" &&
                                                <form style={{ marginTop: (msgData.value === "IF_STAFF" ? "1rem" : "0") }} data-index={index} onSubmit={selectIssueOption}>
                                                    <MessageOption type="submit">Yes</MessageOption>
                                                    <MessageOption type="submit">No</MessageOption>
                                                </form>
                                            }

                                        </Message.CustomContent>
                                    }

                                    {
                                        msgData.value.match("REQUEST_UNIT_NUMBER") !== null &&
                                        <Message.CustomContent>
                                            <span>Please type the unit number of machine. It is located on the left of token cost label.</span>
                                        </Message.CustomContent>
                                    }

                                    {
                                        msgData.value.match("DESCRIBE_TOKEN_ISSUE") !== null &&
                                        <Message.CustomContent>
                                            <span>Can you describe the issue about the coin dispenser?</span>
                                        </Message.CustomContent>
                                    }

                                    {
                                        msgData.value.match("GATCHA_SHOW_PROBLEMS") !== null &&
                                        <Message.CustomContent>
                                            <span>Could you please identify the problem with the gatcha machine? Here are the common issues:</span>

                                            {msgData.value === "GATCHA_SHOW_PROBLEMS" &&
                                                <form style={{ marginTop: "1rem" }} data-index={index} onSubmit={selectIssueOption}>
                                                    <MessageOption type="submit">A) Blank Spin: The machine wheel was turned but nothing came out.</MessageOption>
                                                    <MessageOption type="submit">B) No Spin: The machine wheel couldn't turn, it feels stuck or jammed.</MessageOption>
                                                    <MessageOption type="submit">C) The product is damaged.</MessageOption>
                                                    <MessageOption type="submit">D) Others.</MessageOption>
                                                </form>
                                            }

                                        </Message.CustomContent>
                                    }

                                    {
                                        msgData.value.match("REFUND_OPTIONS") !== null &&
                                        <Message.CustomContent>
                                            <span>How would you like to be refunded?</span>

                                            {msgData.value === "REFUND_OPTIONS" &&
                                                <form style={{ marginTop: "1rem" }} data-index={index} onSubmit={selectIssueOption}>
                                                    <MessageOption type="submit">Refund through tokens</MessageOption>
                                                    <MessageOption type="submit">Refund through Zelle</MessageOption>
                                                </form>
                                            }

                                        </Message.CustomContent>
                                    }

                                    {
                                        msgData.value.match("DECK_LOCATE") !== null &&
                                        <Message.CustomContent>
                                            <span>What the machine's deck location?</span>

                                            {msgData.value === "DECK_LOCATE" &&
                                                <form style={{ marginTop: (msgData.value === "DECK_LOCATE" ? "1rem" : "0") }} data-index={index} onSubmit={selectIssueOption}>
                                                    <MessageOption type="submit">Upper Deck</MessageOption>
                                                    <MessageOption type="submit">Middle Deck</MessageOption>
                                                    <MessageOption type="submit">Lower Deck</MessageOption>
                                                </form>
                                            }

                                        </Message.CustomContent>
                                    }

                                    {
                                        msgData.value.match("CHECK_CAPSULES") !== null &&
                                        <Message.CustomContent>
                                            <span>Does the gatcha machine have any capsules at all? Please check and let me know.</span>

                                            {msgData.value === "CHECK_CAPSULES" &&
                                                <form style={{ marginTop: (msgData.value === "CHECK_CAPSULES" ? "1rem" : "0") }} data-index={index} onSubmit={selectIssueOption}>
                                                    <MessageOption type="submit">A) Yes, there are capsules.</MessageOption>
                                                    <MessageOption type="submit">B) Yes, but the capsules seems to be misplaced.</MessageOption>
                                                    <MessageOption type="submit">C) No, it's empty.</MessageOption>
                                                </form>
                                            }

                                        </Message.CustomContent>
                                    }

                                    {
                                        msgData.value.match("TOKEN_UNIT") !== null &&
                                        <Message.CustomContent>
                                            <span>Please choose the unit number of the coin dispenser.</span>

                                            {msgData.value === "TOKEN_UNIT" &&
                                                <form style={{ marginTop: (msgData.value === "TOKEN_UNIT" ? "1rem" : "0") }} data-index={index} onSubmit={selectIssueOption}>
                                                    <MessageOption type="submit">L1</MessageOption>
                                                    <MessageOption type="submit">L2</MessageOption>
                                                </form>
                                            }

                                        </Message.CustomContent>
                                    }

                                    {
                                        msgData.value.match("CHECK_A_SOLUTIONS") !== null &&
                                        <Message.CustomContent>
                                            <span>Thank you for checking, please try the following and let me know if the solutions worked:
                                                <br /><br />1) Check if the wheel was turned only half way, not 360.
                                                <br />2) See if there is any capsule stuck in the middle of chute.
                                            </span>

                                            {msgData.value === "CHECK_A_SOLUTIONS" &&
                                                <form style={{ marginTop: (msgData.value === "CHECK_A_SOLUTIONS" ? "1rem" : "0") }} data-index={index} onSubmit={selectIssueOption}>
                                                    <MessageOption type="submit">The solutions worked.</MessageOption>
                                                    <MessageOption type="submit">The solutions didn't help.</MessageOption>
                                                </form>
                                            }

                                        </Message.CustomContent>
                                    }

                                    {msgData.value.match("REFUND_METHOD") !== null &&
                                        <Message.CustomContent>
                                            <span>Go to Houston Skateboards at the far-right corner of the food hall, show this text to claim {amtToRefundTokens} token. If nobody is there, please just reselect Zelle option or email us at <a href="mailto:admin@tokyostoryusa.com">admin@tokyostoryusa.com</a>. <br/><br/>DO NOT try to enter Houston Skateboard if nobody is there.</span>

                                            {msgData.value.slice(-4) !== "DONE" &&
                                                <form style={{ marginTop: "1rem" }} data-index={index} onSubmit={selectIssueOption}>
                                                    <MessageOption type="submit">I received the refund.</MessageOption>
                                                    <MessageOption type="submit">Go back to refund options.</MessageOption>
                                                </form>
                                            }
                                        </Message.CustomContent>
                                    }

                                    {msgData.value.match("ZELLE_CONFIRM_") !== null &&
                                        <Message.CustomContent>
                                            <span>Can you confirm if your Zelle information is correct?</span>
                                            <br /><br />
                                            <span><span style={{ fontWeight: "bold" }}>Name:</span> {zelleInfo[2]}</span><br />
                                            <span><span style={{ fontWeight: "bold" }}>Zip Code:</span> {zelleInfo[3]}</span><br />
                                            <span><span style={{ fontWeight: "bold" }}>Phone Number:</span> {zelleInfo[4]}</span>

                                            {msgData.value.slice(-4) !== "DONE" &&
                                                <form style={{ marginTop: "1rem" }} data-index={index} onSubmit={selectIssueOption}>
                                                    <MessageOption type="submit">Yes, I confirm this is correct.</MessageOption>
                                                    <MessageOption type="submit">No, I want to rewrite.</MessageOption>
                                                </form>
                                            }
                                        </Message.CustomContent>
                                    }

                                    {msgData.value === "COMPLETED_TYPE_ZELLE" &&
                                        <Message.CustomContent>
                                            <span>Please allow us 24-48 hours as we review and process your refund through Zelle. Thank you for your support and input.</span>
                                        </Message.CustomContent>
                                    }

                                    {msgData.value === "COMPLETED_TYPE_DEFAULT" &&
                                        <Message.CustomContent>
                                            <span>Thank you for your support and input. Please enjoy the rest of your day at Tokyo Story! Have fun!</span>
                                        </Message.CustomContent>
                                    }

                                </Message>
                            )
                        })}
                    </MessageList>
                    <MessageInput attachButton={false} disabled={!canType || isLastMsgOption} autoFocus={true} attachDisabled={true} onSend={handleSend} placeholder="Type message here" />
                </ChatContainer>
            </MainContainer>
        </div>
    )
}